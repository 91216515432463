import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

// import ImgCoffeeCup from "../img/M_twoj_produkt_12/";

import ImgCoffeeIco from "../img/M_twoj_produkt_12/iko_rodzaj_kawy.png";
import ImgPackagingIco from "../img/M_twoj_produkt_12/iko_rodzaj_opakowania.png";
import ImgDesignIco from "../img/M_twoj_produkt_12/iko_design.png";

import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";
import ImgQualityIcon from "../img/M_twoj_produkt_12/iko_jakosc.png";
import ImgContactIcon from "../img/M_twoj_produkt_12/iko_kontakt.png";

export default function Header(props) {



    return (
            <React.Fragment>
    <section id="customproducts" class="customproducts coffeebeans firstSection">
        <h1>{i18next.t('CustomProductsH1')}</h1>
        <p>{i18next.t('CustomProductsP')}</p>
        <div class="items">

            <div class="item">
                <div> <img loading="lazy" src={ImgCoffeeIco} alt="Instanta Coffee Icon" /></div>
                <div>
                    <h2>{i18next.t('CustomProductCoffee')}</h2>
                    <p>{i18next.t('CustomProductCoffeeDesc')}</p>
                    <div class="link">
                        <Link to={'/OurCoffees/'}>
                        <p>{i18next.t('CustomProductLink1')}</p>
                        </Link></div>
                </div>
            </div>
            <div class="item">
                <div><img loading="lazy" src={ImgPackagingIco} alt="Instanta Packaging Icon" /></div>
                <div>
                    <h2>{i18next.t('CustomProductPackaging')}</h2>
                    <p>{i18next.t('CustomProductPackagingDesc')}</p>
                    <div class="link">
                        <Link to={'/OurPackaging/Packaging'}>
                        <p>{i18next.t('CustomProductLink2')}</p>
                        </Link></div>
                </div>
            </div>
            <div class="item">
                <div><img loading="lazy" src={ImgDesignIco} alt="Instanta Design Icon" /></div>
                <div>
                    <h2>{i18next.t('CustomProductDesign')}</h2>
                    <p>{i18next.t('CustomProductDesignDesc')}</p>
                    <div class="link">
                        <Link to={'/OurPackaging/Design'}>
                        <p>{i18next.t('CustomProductLink3')}</p>
                        </Link></div>
                </div>
            </div>


        </div>

        {/* <div class="cup"><img loading="lazy" src={ImgCoffeeCup} alt="Instanta Coffee Cup" />
         </div> */}

        <aside class="mininavi">
            <div>
                <Link to=
    {'/Collaboration/'}
    >
                <img loading="lazy" src=
    {ImgOurCoffeeBack}
    alt="Instanta Coffee Back Icon" />
                <p>
    {i18next.t('YourProductMiniMenu1')}
    </p>
                </Link>
            </div>
            <div>
                <Link to=
    {'/Quality/'}
    >
                <img loading="lazy" src=
    {ImgQualityIcon}
    alt="Instant Quality Icon" />
                <p>
    {i18next.t('YourProductMiniMenu2')}
    </p>
                </Link>
            </div>   <div>
                <Link to=
    {'/Contact/'}
    >
                <img loading="lazy" src=
    {ImgContactIcon}
    alt="Instanta Contact Icon" />
                <p>
    {i18next.t('YourProductMiniMenu3')}
    </p>
                </Link>
            </div>
        </aside>
    </section>


</React.Fragment>

    )


}
